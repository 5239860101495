import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import { useLanguage } from '../../../language/language-provider';
import Asset from '../../../primitives/asset';
import NotchedSlider from '../../../primitives/notched-slider';
import Typography from '../../../primitives/typography';
import { colors } from '../../../primitives/colors';
import Background from '../../../primitives/backgrounds';
import CapterraBadge from '../../../primitives/quality-badges/capterra-badge.svg';


export type CapterraProps = Queries.CapterraPropsFragment;

const Capterra = ({ reviews }: CapterraProps): JSX.Element => {

    return (
        <Background
            breakpoints={{
                dt: {
                    backgroundColor: 'grey50',
                },
            }}
        >
            <Container css={{
                paddingTop: '68px',
                paddingBottom: '68px',
                [breakpoints.mb]: {
                    paddingTop: '34px',
                    paddingBottom: '34px',
                },
            }}
            >
                <NotchedSlider autoPlay={reviews.length > 1 ? 5 : undefined}>
                    <Row>
                        <Col breakpoints={{
                            dt: { span: 8, start: 2 },
                            tb: { span: 12, start: 0 },
                            mb: { span: 4, start: 0 },
                        }}
                        >
                            <div css={{
                                display: 'flex',
                                gap: '48px',
                                alignItems: 'center',
                                [breakpoints.tb]: {
                                    gap: '32px',
                                },
                                [breakpoints.mb]: {
                                    flexDirection: 'column',
                                }
                            }}
                            >
                                <div>
                                    <CapterraBadge
                                        css={{
                                            width: '140px',
                                        }}
                                    />
                                </div>
                                <div css={{
                                    padding: '28px',
                                    backgroundColor: colors.shadesWhite,
                                    borderRadius: borderRadius.small,
                                    width: 'calc(100% - 140px)',
                                    [breakpoints.mb]: {
                                        width: '100%',
                                    }
                                }}>
                                    <NotchedSlider.Fade>
                                        {reviews.map((review) => {
                                            const percentage = Math.round((review.stars / 5) * 100);

                                            return (
                                                <div
                                                >
                                                    <Typography
                                                        fontSize={{
                                                            dt: 2429,
                                                            tb: 1822,
                                                            mb: 1627,
                                                        }}
                                                        fontWeight="medium"
                                                        dangerouslySetInnerHTML={{
                                                            __html: review.review,
                                                        }}
                                                    />
                                                    <div css={{
                                                        marginTop: '16px',
                                                        display: 'flex',
                                                        gap: '16px',
                                                    }}
                                                    >
                                                        <div css={{
                                                            display: 'flex',
                                                            gap: '8px',
                                                            alignItems: 'center',
                                                        }}
                                                        >
                                                            <div css={{
                                                                display: 'inline-flex',
                                                                alignItems: 'center',
                                                                position: 'relative',
                                                            }}
                                                            >
                                                                {Array.from(Array(5).keys()).map((_, i) => (
                                                                    <svg
                                                                        css={{
                                                                            width: '18px',
                                                                            marginRight: '2px',
                                                                            display: 'flex',
                                                                            color: colors.secondaryOrange,
                                                                            '&:last-of-type': {
                                                                                marginRight: '0',
                                                                            },
                                                                        }}
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        {/* eslint-disable-next-line max-len */}
                                                                        <path d="M12.8649 2.99628C12.4796 2.33213 11.5204 2.33213 11.1351 2.99628L8.42101 7.67359C8.23064 8.00168 7.90159 8.22557 7.52653 8.28222L2.44021 9.05044C1.58593 9.17947 1.28627 10.2581 1.95158 10.8093L5.74067 13.9485C6.09141 14.2391 6.25633 14.6975 6.17113 15.1449L5.17996 20.35C5.02327 21.1729 5.88706 21.8122 6.62821 21.4219L11.4176 18.9001C11.7821 18.7082 12.2178 18.7082 12.5824 18.9001L17.3718 21.4219C18.1129 21.8122 18.9767 21.1729 18.82 20.35L17.8289 15.1449C17.7437 14.6975 17.9086 14.2391 18.2593 13.9485L22.0484 10.8093C22.7137 10.2581 22.4141 9.17947 21.5598 9.05044L16.4735 8.28222C16.0984 8.22557 15.7694 8.00168 15.579 7.67359L12.8649 2.99628Z" fill="currentColor" />
                                                                    </svg>
                                                                ))}
                                                                {
                                                                    /* Render a div overlayed on top of the stars that should not be not filled */
                                                                }
                                                                <div
                                                                    css={{
                                                                        backgroundColor: 'black',
                                                                        position: 'absolute',
                                                                        top: '0',
                                                                        right: '0',
                                                                        bottom: '0',
                                                                        zIndex: 1,
                                                                        mixBlendMode: 'color',
                                                                    }}
                                                                    style={{ width: `${100 - percentage}%` }}
                                                                />
                                                            </div>
                                                            <Typography
                                                                color="grey200"
                                                                fontSize={{
                                                                    dt: 1627,
                                                                    tb: 1420,
                                                                    mb: 1322,
                                                                }}
                                                                css={{
                                                                    opacity: 0.8,
                                                                }}
                                                                fontWeight="light"
                                                            >
                                                                {review.stars.toFixed(1)}
                                                            </Typography>
                                                        </div>
                                                        <Typography
                                                            color="grey200"
                                                            fontSize={{
                                                                dt: 1822,
                                                                tb: 1627,
                                                                mb: 1420,
                                                            }}
                                                            css={{
                                                                opacity: 0.8,
                                                            }}
                                                        >
                                                            {review.biLine}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </NotchedSlider.Fade>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </NotchedSlider>
            </Container>
        </Background>
    );
};

export default Capterra;

export const query = graphql`
    fragment CapterraProps on DatoCmsCapterra {
        __typename
        id
        reviews {
            review
            stars
            biLine
        }
    }
`;
